export class CartHandler {

    #addToCartUrl;
    #addToCartMultipleUrl;
    #getCartMiniHtml;
    #changeAmountUrl;
    #removeFromCartUrl;
    #clearCartUrl;
    #actionAfterAddingProductToCart;
    #cartUrl;
    #getPopupContentHtml;
    #setDeliveryMethodUrl;
    #setPaymentMethodUrl;
    #setDeliveryMethodExtraDataUrl;
    #setPaymentMethodExtraDataUrl;
    #afterAddingProductToCarPopupTrigger;
    #afterAddingProductToCarPopupContentContainer;
    #broadcastChannel;

    constructor(addToCartUrl, addToCartMultipleUrl, getCartMiniHtml, changeAmountUrl, removeFromCartUrl, clearCartUrl,
                actionAfterAddingProductToCart, cartUrl, getPopupContentHtml, setDeliveryMethodUrl, setPaymentMethodUrl,
                setDeliveryMethodExtraDataUrl, setPaymentMethodExtraDataUrl) {

        this.#broadcastChannel = new BroadcastChannel('new_product_in_cart_channel');

        this.#addToCartUrl = addToCartUrl;
        this.#addToCartMultipleUrl = addToCartMultipleUrl;
        this.#getCartMiniHtml = getCartMiniHtml;
        this.#changeAmountUrl = changeAmountUrl;
        this.#removeFromCartUrl = removeFromCartUrl;
        this.#clearCartUrl = clearCartUrl;
        this.#actionAfterAddingProductToCart = actionAfterAddingProductToCart;
        this.#cartUrl = cartUrl;
        this.#getPopupContentHtml = getPopupContentHtml;
        this.#setDeliveryMethodUrl = setDeliveryMethodUrl;
        this.#setPaymentMethodUrl = setPaymentMethodUrl;
        this.#setDeliveryMethodExtraDataUrl = setDeliveryMethodExtraDataUrl;
        this.#setPaymentMethodExtraDataUrl = setPaymentMethodExtraDataUrl;

        this.#afterAddingProductToCarPopupTrigger = document.getElementById('after_adding_product_to_cart_popup_trigger');
        this.#afterAddingProductToCarPopupContentContainer = document.getElementById('after_adding_product_to_cart_popup_content_container');

        this.#bindListeners();
    }

    attachHandlerToProducts() {
        document.querySelectorAll('[data-ready-to-add-to-cart]').forEach(elem => {
            elem.addEventListener('click', event => {
                const addProductEvent = new CustomEvent('cart_add_product', {
                    detail: {
                        productId: event.currentTarget.dataset.cartProductId,
                        amount: event.currentTarget.dataset.cartAmount,
                        button: event.currentTarget,
                    }
                });

                document.dispatchEvent(addProductEvent);
            });
        });
    }

    #bindListeners() {
        this.attachHandlerToProducts();

        document.addEventListener(
            'cart_add_product',
            event => {
                this.addToCart(event.detail.button, event.detail.productId, event.detail.amount, JSON.stringify([]));
            }
        )

        document.addEventListener(
            'cart_add_multiple_products',
            event => {
                this.addToCartMultiple(event.detail.products);
            }
        )

        document.addEventListener(
            'cart_add_bundle_product',
            event => {
                this.addToCart(event.detail.button, event.detail.productId, event.detail.amount, JSON.stringify(event.detail.options));
            }
        )

        document.addEventListener(
            'cart_add_composite_product',
            event => {
                this.addToCart(event.detail.button, event.detail.productId, event.detail.amount, JSON.stringify(event.detail.options));
            }
        )

        document.addEventListener(
            'cart_change_amount',
            event => {
                this.changeAmount(event.detail.hash, event.detail.amount, event.detail.old_amount);
            }
        )

        document.addEventListener(
            'cart_remove_product',
            event => {
                this.removeProductFromCart(event.detail.hash);
            }
        )

        document.addEventListener(
            'cart_clear_cart',
            event => {
                this.clearCart();
            }
        )

        document.addEventListener(
            'cart_set_delivery_method',
            event => {
                this.setDeliveryMethod(event.detail.method);
            }
        )

        document.addEventListener(
            'cart_set_payment_method',
            event => {
                this.setPaymentMethod(event.detail.method);
            }
        )

        document.addEventListener(
            'cart_delivery_method_set_extra_data',
            event => {
                this.setDeliveryMethodExtraData(event.detail.method, event.detail.extra_data);
            }
        );

        document.addEventListener(
            'cart_payment_method_set_extra_data',
            event => {
                this.setPaymentMethodExtraData(event.detail.method, event.detail.extra_data);
            }
        );

        document.addEventListener(
            'catalog_new_products_appeared',
            event => {
                this.attachHandlerToProducts();
            }
        )
    }

    async addToCart(button, productId, amount, optionsJson) {
        button.disabled = true;

        const body = new FormData();
        body.append('product_id', productId);
        body.append('amount', amount);
        body.append('options', optionsJson);

        const response = await fetch(this.#addToCartUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        if (response.success) {
            button.innerText = response.buttonTextReplacement;

            switch (this.#actionAfterAddingProductToCart) {
                case 'show_popup':
                    this.refreshCart();
                    this.showProductPopup(response.product_hash);
                    break;
                case 'go_to_cart':
                    window.location.href = this.#cartUrl;
                    break;
                case 'refresh_widget':
                default:
                    this.refreshCart();
            }

            this.#broadcastChannel.postMessage({
                mode: 'single',
                product_id: productId,
                amount: amount
            });

        } else {
            button.disabled = false;
            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();
        }
    }

    async addToCartMultiple(products) {
        const body = new FormData();
        body.append('products', JSON.stringify(products));

        const response = await fetch(this.#addToCartMultipleUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        if (response.success) {
            switch (this.#actionAfterAddingProductToCart) {
                case 'go_to_cart':
                    window.location.href = this.#cartUrl;
                    break;
                case 'show_popup':
                case 'refresh_widget':
                default:
                    this.refreshCart();
            }

            new Iceberg.Toast({
                title: response.message_header,
                body: response.message,
                color: response.status === 'all_products_added' ? 'success' : 'info',
                duration: 15000,
            }).show();

            this.#broadcastChannel.postMessage({
                mode: 'multiple'
            });
        } else {
            new Iceberg.Toast({
                title: 'Error',
                body: response.reason,
                color: 'danger',
                duration: 5000,
            }).show();
        }
    }

    async changeAmount(productHash, amount, oldAmount) {
        const body = new FormData();
        body.append('product_hash', productHash);
        body.append('amount', amount);
        body.append('old_amount', oldAmount);

        const response = await fetch(this.#changeAmountUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const afterEvent = new CustomEvent('cart_change_amount_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(afterEvent);
    }

    async removeProductFromCart(productHash) {
        const body = new FormData();
        body.append('product_hash', productHash);

        const response = await fetch(this.#removeFromCartUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const afterEvent = new CustomEvent('cart_remove_product_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(afterEvent);
    }

    async clearCart() {
        const response = await fetch(this.#clearCartUrl, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        window.location.href = response.redirect || '/';
    }

    async refreshCart() {
        document.getElementById('mini_cart_container').innerHTML = await fetch(this.#getCartMiniHtml, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.text());
    }

    async showProductPopup(productHash) {
        const body = new FormData();
        body.append('product_hash', productHash);

        this.#afterAddingProductToCarPopupContentContainer.innerHTML = await fetch(this.#getPopupContentHtml, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.text());

        this.#afterAddingProductToCarPopupTrigger.click();
    }

    async setDeliveryMethod(method) {
        const body = new FormData();
        body.append('method', method);

        const response = await fetch(this.#setDeliveryMethodUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const setDeliveryMethodResponseEvent = new CustomEvent('cart_set_delivery_method_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(setDeliveryMethodResponseEvent);
    }

    async setPaymentMethod(method) {
        const body = new FormData();
        body.append('method', method);

        const response = await fetch(this.#setPaymentMethodUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const setDeliveryMethodResponseEvent = new CustomEvent('cart_set_payment_method_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(setDeliveryMethodResponseEvent);
    }

    async setDeliveryMethodExtraData(method, extraData) {
        const body = new FormData();
        body.append('method', method);
        body.append('extra_data', JSON.stringify(extraData));

        const response = await fetch(this.#setDeliveryMethodExtraDataUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const responseEvent = new CustomEvent('cart_delivery_method_set_extra_data_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(responseEvent);
    }

    async setPaymentMethodExtraData(method, extraData) {
        const body = new FormData();
        body.append('method', method);
        body.append('extra_data', JSON.stringify(extraData));

        const response = await fetch(this.#setPaymentMethodExtraDataUrl, {
            method: 'POST',
            body: body,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(res => res.json());

        const responseEvent = new CustomEvent('cart_payment_method_set_extra_data_response', {
            detail: {
                response: response,
            }
        });

        document.dispatchEvent(responseEvent);
    }
}

export class CartManager {
    #amountInputs = {};
    #amountChangeIndicators = {};
    #continueToContactDataButton = null;
    #eventsTranslations = {};
    #deliveryPaymentConnections = {};

    constructor() {
        this.#broadcastChannel();

        document.querySelectorAll('[data-cart-item-amount]')
            .forEach(input => this.#amountInputs[input.dataset.cartItemHash] = input);

        document.querySelectorAll('[data-cart-item-change-amount-indicator]')
            .forEach(span => this.#amountChangeIndicators[span.dataset.cartItemHash] = span);

        this.#continueToContactDataButton = document.getElementById('cart_continue_to_contact_data');
        if (this.#continueToContactDataButton) {
            this.#tryShowContinueButton();
        }

        this.#eventsTranslations = JSON.parse(document.getElementById('cart_events_translations').value);

        this.#deliveryPaymentConnections = JSON.parse(document.getElementById('cart_delivery_payment_connections').value);

        this.#bindListeners();
        this.#bindChangeAmountResponder();
        this.#bindRemoveProductResponder();
        this.#bindSetDeliveryMethodResponder();
        this.#bindSetPaymentMethodResponder();
        this.#bindSetDeliveryMethodExtraDataResponder();
        this.#bindSetPaymentMethodExtraDataResponder();
    }

    #broadcastChannel() {
        const bc = new BroadcastChannel('new_product_in_cart_channel');
        bc.onmessage = ev => window.location.reload();
    }

    #bindListeners() {
       const clearCartButton = document.getElementById('clear_cart_trigger');
        if (clearCartButton) {
            clearCartButton.addEventListener('click', () => {
                document.dispatchEvent(new CustomEvent('cart_clear_cart'));
            })
        }

        const incrementButtons = document.querySelectorAll('[data-cart-item-increment]');
        incrementButtons.forEach(btn => btn.addEventListener('click', event => {
            const input = this.#amountInputs[btn.dataset.cartItemHash];
            if (!input) {
                new Iceberg.Toast({
                    title: 'Error',
                    body: "Cart item not found.",
                    color: 'danger',
                    duration: 5000,
                }).show();
                return;
            }

            const currentValue = input.value;
            const newValue = (parseFloat(currentValue).toFixed(2) * 100 + parseFloat(btn.dataset.cartItemIncrement).toFixed(2) * 100) / 100;

            input.value = input.dataset.cartItemAmountIsInt == 1 ? parseInt(newValue) : newValue.toFixed(2);
            input.dispatchEvent(new Event('change'));
        }));

        const decrementButtons = document.querySelectorAll('[data-cart-item-decrement]');
        decrementButtons.forEach(btn => btn.addEventListener('click', event => {
            const input = this.#amountInputs[btn.dataset.cartItemHash];
            if (!input) {
                new Iceberg.Toast({
                    title: 'Error',
                    body: "Cart item not found.",
                    color: 'danger',
                    duration: 5000,
                }).show();
                return;
            }

            const currentValue = input.value;
            const newValue = (parseFloat(currentValue).toFixed(2) * 100 - parseFloat(btn.dataset.cartItemDecrement).toFixed(2) * 100) / 100;

            if (newValue > 0) {
                input.value = input.dataset.cartItemAmountIsInt == 1 ? parseInt(newValue) : newValue.toFixed(2);
                input.dispatchEvent(new Event('change'));
            } else {
                input.value = currentValue;
            }
        }));

        const removeButtons = document.querySelectorAll('[data-cart-item-remove]');
        removeButtons.forEach(btn => btn.addEventListener('click', event => {
            btn.disabled = true;

            const removeEvent = new CustomEvent('cart_remove_product', {
                detail: {
                    hash: btn.dataset.cartItemHash,
                }
            });
            document.dispatchEvent(removeEvent);
        }));

        Object.values(this.#amountInputs).forEach(input => input.addEventListener('change', event => {
            const newAmount = input.value;
            const oldAmount = input.dataset.cartItemOldAmount;

            if (newAmount <= 0) {
                input.value = oldAmount;
                return;
            }

            input.dataset.cartItemOldAmount = input.value;

            const indicator = this.#amountChangeIndicators[input.dataset.cartItemHash];
            if (indicator) {
                indicator.classList.remove('d-none');
            }

            const cartEvent = new CustomEvent('cart_change_amount', {
                detail: {
                    hash: input.dataset.cartItemHash,
                    amount: newAmount,
                    old_amount: oldAmount,
                }
            });

            document.dispatchEvent(cartEvent);
        }));

        if (this.#continueToContactDataButton) {
            this.#continueToContactDataButton.addEventListener('click', (e) => {

                const deliveryMethod = document.querySelector('[name="delivery_method"]:checked');
                if (null === deliveryMethod) {
                    new Iceberg.Toast({
                        title: 'Error',
                        body: this.#eventsTranslations["delivery_method_not_selected"],
                        color: 'danger',
                        duration: 5000,
                    }).show();

                    e.preventDefault();
                    e.stopPropagation();
                }

                const paymentMethod = document.querySelector('[name="payment_method"]:checked');
                if (null === paymentMethod) {
                    new Iceberg.Toast({
                        title: 'Error',
                        body: this.#eventsTranslations["payment_method_not_selected"],
                        color: 'danger',
                        duration: 5000,
                    }).show();

                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        }

        document.querySelectorAll('[name="delivery_method"]').forEach(elem => elem.addEventListener('change', e => {
            const setDeliveryMethodEvent = new CustomEvent('cart_set_delivery_method', {
                detail: {
                    method: e.currentTarget.value,
                }
            });

            window.Iceberg.CartUtils.addOverlay('cart_set_delivery_method', document.getElementById('cart_content_table_footer'));

            document.dispatchEvent(setDeliveryMethodEvent);
        }));

        document.querySelectorAll('[name="payment_method"]').forEach(elem => elem.addEventListener('change', e => {
            const setPaymentMethodEvent = new CustomEvent('cart_set_payment_method', {
                detail: {
                    method: e.currentTarget.value,
                }
            });

            window.Iceberg.CartUtils.addOverlay('cart_set_payment_method', document.getElementById('cart_content_table_footer'));

            document.dispatchEvent(setPaymentMethodEvent);
        }));
    }

    #bindChangeAmountResponder() {
        document.addEventListener(
            'cart_change_amount_response',
            event => {
                const response = event.detail.response;
                const hash = response.hash;

                const indicator = this.#amountChangeIndicators[hash];
                if (indicator) {
                    indicator.classList.add('d-none');
                }

                if (!response.success) {
                    const input = this.#amountInputs[hash];
                    if (input) {
                        input.value = response.old_amount;
                        input.dataset.cartItemOldAmount = response.old_amount;
                    }

                    if (response.status === 'product_not_found') {
                        const row = document.getElementById('cart_item_row_' + hash);
                        if (row) {
                            row.remove();
                        }

                        this.#updateSummaryPrices(response);
                        this.#updateDeliveryAndPaymentMethods(response);
                    }

                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();

                    return;
                }

                let ref = document.getElementById('price_with_tax_' + hash);
                if (ref) {
                    ref.innerText = response.price_with_tax;
                }

                ref = document.getElementById('price_without_tax_' + hash);
                if (ref) {
                    ref.innerText = response.price_without_tax;
                }

                ref = document.getElementById('best_price_with_tax_' + hash);
                if (ref) {
                    ref.innerText = response.best_price_with_tax;
                }

                ref = document.getElementById('best_price_without_tax_' + hash);
                if (ref) {
                    ref.innerText = response.best_price_without_tax;
                }

                ref = document.getElementById('total_price_with_tax_' + hash);
                if (ref) {
                    ref.innerText = response.total_price_with_tax;
                }

                ref = document.getElementById('total_price_without_tax_' + hash);
                if (ref) {
                    ref.innerText = response.total_price_without_tax;
                }

                ref = document.getElementById('total_best_price_with_tax_' + hash);
                if (ref) {
                    ref.innerText = response.total_best_price_with_tax;
                }

                ref = document.getElementById('total_best_price_without_tax_' + hash);
                if (ref) {
                    ref.innerText = response.total_best_price_without_tax;
                }

                this.#updateSummaryPrices(response);
                this.#updateDeliveryAndPaymentMethods(response);
            }
        )
    }

    #bindRemoveProductResponder() {
        document.addEventListener(
            'cart_remove_product_response',
            event => {
                const response = event.detail.response;

                if (!response.success) {

                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();
                    return;

                }

                const hash = response.hash;
                const row = document.getElementById('cart_item_row_' + hash);
                if (row) {
                    row.remove();
                }

                this.#updateSummaryPrices(response);
                this.#updateDeliveryAndPaymentMethods(response);
            }
        )
    }

    #bindSetDeliveryMethodResponder() {
        document.addEventListener(
            'cart_set_delivery_method_response',
            event => {
                window.Iceberg.CartUtils.removeOverlay('cart_set_delivery_method');
                const response = event.detail.response;

                if (!response.success) {
                    this.#clearSelectedDeliveryMethod();
                    this.#hideContinueButton();
                    this.#showAllPaymentMethod();

                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();
                } else {
                   this.#afterSelectDeliveryMethod(response);
                }

                // niezależnie czy akcja się udała, czy nie, to aktualizujemy ceny
                document.getElementById('cart_delivery_cost_container').textContent = response.is_free_delivery ? '0.00' : response.delivery_price_with_tax_formatted;
                document.getElementById('cart_payment_cost_container').textContent = response.payment_price_with_tax_formatted;
                document.getElementById('cart_total_to_pay').textContent = response.final_price_with_tax_formatted;
            }
        )
    }

    #bindSetPaymentMethodResponder() {
        document.addEventListener(
            'cart_set_payment_method_response',
            event => {
                window.Iceberg.CartUtils.removeOverlay('cart_set_payment_method');
                const response = event.detail.response;

                if (!response.success) {
                    this.#clearSelectedPaymentMethod();
                    this.#hideContinueButton();

                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();
                } else {
                   this.#afterSelectPaymentMethod(response);
                }

                // niezależnie czy akcja się udała, czy nie, to aktualizujemy ceny
                document.getElementById('cart_payment_cost_container').textContent = response.payment_price_with_tax_formatted;
                document.getElementById('cart_total_to_pay').textContent = response.final_price_with_tax_formatted;
            }
        )
    }

    #bindSetDeliveryMethodExtraDataResponder() {
        document.addEventListener(
            'cart_delivery_method_set_extra_data_response',
            event => {
                window.Iceberg.CartUtils.removeOverlay('cart_set_delivery_method_extra_data');
                const response = event.detail.response;
                if (!response.success) {
                    document.getElementById('is_cart_delivery_method_extra_data_valid').value = 0;
                    this.#hideContinueButton();

                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();
                } else {
                    document.getElementById('is_cart_delivery_method_extra_data_valid').value = response.is_extra_data_valid;
                    this.#tryShowContinueButton();
                }
            }
        )
    }

    #bindSetPaymentMethodExtraDataResponder() {
        document.addEventListener(
            'cart_payment_method_set_extra_data_response',
            event => {
                window.Iceberg.CartUtils.removeOverlay('cart_set_payment_method_extra_data');
                const response = event.detail.response;
                if (!response.success) {
                    document.getElementById('is_cart_delivery_method_extra_data_valid').value = 0;
                    this.#hideContinueButton();


                    new Iceberg.Toast({
                        title: 'Error',
                        body: response.reason,
                        color: 'danger',
                        duration: 5000,
                    }).show();
                } else {
                    document.getElementById('is_cart_payment_method_extra_data_valid').value = response.is_extra_data_valid;
                    this.#tryShowContinueButton();
                }
            }
        )
    }

    #tryShowContinueButton() {
        const deliveryMethodValid = document.getElementById('is_cart_delivery_method_valid').value == 1;
        const deliveryMethodExtraDataValid = document.getElementById('is_cart_delivery_method_extra_data_valid').value == 1;
        const paymentMethodValid = document.getElementById('is_cart_payment_method_valid').value == 1;
        const paymentMethodExtraDataValid = document.getElementById('is_cart_payment_method_extra_data_valid').value == 1;

        if (deliveryMethodValid && paymentMethodValid && deliveryMethodExtraDataValid && paymentMethodExtraDataValid) {
            this.#continueToContactDataButton.classList.remove('d-none');
        } else {
            this.#hideContinueButton();
        }
    }

    #hideContinueButton() {
        this.#continueToContactDataButton.classList.add('d-none');
    }

    #showAllPaymentMethod() {
        document.querySelectorAll('[data-payment-method-container]')
            .forEach(container => container.classList.remove('d-none'));
    }

    #updatePaymentMethodVisibilities(visiblePayments) {
        const paymentMethodContainers = document.querySelectorAll('[data-payment-method-container]')
            .forEach(container => {
                if (visiblePayments.includes(container.dataset.paymentMethodContainer)) {
                    container.classList.remove('d-none');
                } else {
                    container.classList.add('d-none');
                }
            });
    }

    #clearSelectedDeliveryMethod() {
        document.getElementById('is_cart_delivery_method_valid').value = 0;
        document.getElementById('is_cart_delivery_method_extra_data_valid').value = 0;
        const checkedMethod = document.querySelector('[name="delivery_method"]:checked');
        if (checkedMethod) {
            checkedMethod.checked = false;
        }

        document.querySelectorAll('[data-delivery-content]').forEach(elem => elem.classList.add('d-none'));

        this.#tryShowContinueButton();
    }

    #afterSelectDeliveryMethod(response) {
        document.getElementById('is_cart_delivery_method_valid').value = 1;
        document.getElementById('is_cart_delivery_method_extra_data_valid').value = response.is_extra_data_valid;
        this.#tryShowContinueButton();
        document.querySelectorAll('[data-delivery-content]').forEach(elem => elem.classList.add('d-none'));
        document.querySelector('[data-delivery-content="'+ response.token +'"]')
            .classList.remove('d-none');

        if (response.clear_payment_method) {
            this.#clearSelectedPaymentMethod();
        }

        this.#updatePaymentMethodVisibilities(Object.values(response.available_payment_methods));
    }

    #clearSelectedPaymentMethod() {
        document.getElementById('is_cart_payment_method_valid').value = 0;
        document.getElementById('is_cart_payment_method_extra_data_valid').value = 0;
        const checkedMethod = document.querySelector('[name="payment_method"]:checked');
        if (checkedMethod) {
            checkedMethod.checked = false;
        }

        document.querySelectorAll('[data-payment-content]').forEach(elem => elem.classList.add('d-none'));

        this.#tryShowContinueButton();
    }

    #afterSelectPaymentMethod(response) {
        document.getElementById('is_cart_payment_method_valid').value = 1;
        document.getElementById('is_cart_payment_method_extra_data_valid').value = response.is_extra_data_valid;
        this.#tryShowContinueButton();

        document.querySelectorAll('[data-payment-content]').forEach(elem => elem.classList.add('d-none'));

        document.querySelector('[data-payment-content="'+ response.token +'"]')
            .classList.remove('d-none');
    }

    #updateSummaryPrices(response) {
        let ref = document.getElementById('cart_total_price');
        if (ref) {
            ref.innerText = response.cart_total_smart;
        }

        ref = document.getElementById('cart_total_to_pay');
        if (ref) {
            ref.innerText = response.cart_total_with_tax_and_delivery;
        }

        ref = document.getElementById('cart_tax');
        if (ref) {
            ref.innerText = response.cart_tax;
        }

        ref = document.getElementById('cart_delivery_cost_container');
        if (ref) {
            ref.innerText = response.is_free_delivery ? '0.00' : response.cart_delivery;
        }

        ref = document.getElementById('cart_payment_cost_container');
        if (ref) {
            ref.innerText = response.cart_payment;
        }
    }

    #updateDeliveryAndPaymentMethods(response) {
        if (response.should_clear_selected_delivery_method) {
            this.#clearSelectedDeliveryMethod();
            this.#showAllPaymentMethod();
        }

        if (response.payment_methods_prices) {
            Object.entries(response.payment_methods_prices).forEach(entry => {
                const [method, price] = entry;
                const container = document.getElementById('payment_method_price_' + method);
                if (container) {
                    container.textContent = price;
                }
            });
        }

        if (response.available_delivery_methods) {
            const availableMethods = Object.keys(response.available_delivery_methods);
            document.querySelectorAll('[data-delivery-method-container]').forEach(elem => {
                const token = elem.dataset.deliveryMethodContainer;
                if (availableMethods.includes(token)) {
                    elem.classList.remove('d-none');
                    document.getElementById('delivery_method_price_' + token).textContent =
                        response.available_delivery_methods[token]['price'];
                    if (response.available_delivery_methods[token]['is_free_delivery']) {
                        document.getElementById('delivery_method_price_container_' + token).classList.add('d-none');
                        document.getElementById('delivery_method_free_delivery_' + token).classList.remove('d-none');
                    } else {
                        document.getElementById('delivery_method_price_container_' + token).classList.remove('d-none');
                        document.getElementById('delivery_method_free_delivery_' + token).classList.add('d-none');
                    }
                } else {
                    elem.classList.add('d-none');
                }
            });
        } else {
            document.querySelectorAll('[data-delivery-method-container]').forEach(elem => elem.classList.add('d-none'));
            this.#clearSelectedDeliveryMethod();
            this.#showAllPaymentMethod();
        }
    }

}

export class CartUtils {
    static addOverlay(id, element) {
        if (!element) {
            element = "body";
        }

        let overlay = document.createElement('div');
        overlay.id = id;

        const style = overlay.style;

        style.backgroundColor = '#000000';
        style.height = '100%';
        style.width = '100%';
        style.position = 'absolute';
        style.top = '0';
        style.left = '0';
        style.zIndex = '910';
        style.display = 'block';
        style.opacity = '0.5';

        element.append(overlay);
    }

    static removeOverlay(id) {
        document.getElementById(id).remove();
    }
}
